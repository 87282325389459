<template>
  <el-row class="table">
    <span class="title">试卷管理</span>
    <el-row class="row-bg">
      <el-input
        placeholder="输入搜索试卷名称、id"
        v-model.trim="queryData.inputVal"
        class="tb-search-input"
        @keyup.enter.native="search"
      >
        <el-select
          v-model="queryData.select"
          slot="prepend"
          placeholder="请选择"
          style="width: 120px;"
        >
          <el-option label="试卷名称" value="1"></el-option>
          <el-option label="试卷id" value="2"></el-option>
        </el-select>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
        <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
      </el-input>
      <el-input
        class="tb-sm-input"
        placeholder="请输入课程名称"
        v-model="queryData.lessonVal"
        @keyup.enter.native="search"
      ></el-input>
      <el-select v-model="queryData.selectStatus" placeholder="请选择试卷状态" class="tb-smselect">
        <el-option
          v-for="item in queryOption"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-cascader
        class="td-cascader"
        v-model="queryData.catalogId"
        placeholder="请选择类别"
        :options="cascaderList"
        :props="{ checkStrictly: true, value: 'id', label: 'title' }">
      </el-cascader>
      <el-button type="primary" size="medium" @click="search()" class="tb-button">查 询</el-button>
      <el-button type="primary" size="medium" @click="reset()" class="tb-button">重 置</el-button>
      <el-button type="info" size="medium" @click="addPaper()" class="tb-button" v-has="'exam:ManageAdd'">新 建</el-button>
    </el-row>
    <el-table :data="dataList" border fit highlight-current-row class="tb-list">
      <el-table-column align="center" label="试卷id" width="90">
        <template slot-scope="scope">
          <span>{{ scope.row.id | uuidFormat }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="试卷名称" width="200">
        <template slot-scope="scope">
          <span @click="previewPaper(scope.row)" class="title-link">{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类别名称" align="left">
        <template slot-scope="scope">
          {{scope.row.catalogName}}
        </template>
      </el-table-column>
      <el-table-column label="课程/课节/任务" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.courseTitle">{{scope.row.courseTitle}}/</span>
          <span v-else>无课程/</span>
          <span v-if="scope.row.courseUnitTitle">{{scope.row.courseUnitTitle}}/</span>
          <span v-else>无课节/</span>
          <span v-if="scope.row.taskList.length !== 0">
            <span v-for="item of scope.row.taskList" :key="item.id">{{item.title}}</span>
          </span>
          <span v-else>无任务</span>
        </template>
      </el-table-column>
      <!-- <el-table-column align="left" label="关联任务" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.taskList !== null">
            <span v-for="item of scope.row.taskList" :key="item.id">{{item.title}}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="关联课程" width="200">
        <template slot-scope="scope">
          <span>{{scope.row.courseTitle}}</span>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="题目数量" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.questionCount }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建人" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.creatorInfo !== null">{{ scope.row.creatorInfo.name }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" width="100">
        <template slot-scope="scope">
          <span :style="'color'+':'+getStateLessonColor(scope.row.status)">{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="答题时长/mins" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.limitTime === 0">不限时</span>
          <span v-else>{{ scope.row.limitTime }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" width="170">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | dateFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button type="primary" size="small">
              管 理
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="editPaper(scope.row)"
                v-has="'exam:ManageEdit'"
              >
                <span>编辑试卷</span>
              </el-dropdown-item>
              <template v-if="scope.row.status === '未发布'">
                <el-dropdown-item @click.native="publicPaper(scope.row)" v-has="'exam:ManagePub'">
                  <span>发布试卷</span>
                </el-dropdown-item>
              </template>
              <template v-if="scope.row.status === '已发布'">
                <el-dropdown-item @click.native="pausePaper(scope.row)" v-has="'exam:ManagePause'">
                  <span>停用试卷</span>
                </el-dropdown-item>
              </template>
              <el-dropdown-item @click.native="copyPaper(scope.row)">
                <span>复制试卷</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="delPaper(scope.row)" v-has="'exam:ManageDel'">
                <span>删除试卷</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- 新增 编辑 试卷 -->
    <add-paper
      :show="paperDialog"
      @close="paperDialog=false;editPaperData=null;isCopy=false"
      :isPublish="false"
      :isCopy="isCopy"
      :editPaperData="editPaperData"
      @addData="addData"
      @editData="editData"
    ></add-paper>
  </el-row>
</template>

<script>
import getStateColor from '@/mixins/getStateColor'
import addPaper from '@/components/research/exam-manage/addPaper'
import { getExamList, addExam, editExam, delExam } from '@/api/research/exam'
import getCourseType from '@/mixins/getCourseType'

export default {
  components: {
    addPaper
  },
  mixins: [getStateColor, getCourseType],
  data () {
    return {
      paperDialog: false,
      editPaperData: null,
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      queryData: {
        inputVal: '',
        select: '1',
        selectStatus: '',
        lessonVal: ''
      },
      queryOption: [
        {
          label: '已发布',
          value: '已发布'
        },
        {
          label: '已停用',
          value: '已停用'
        }
      ],
      isCopy: false
    }
  },
  created () {
    this.getExamList()
  },
  methods: {
    async getExamList () {
      const res = await getExamList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        idKey: this.queryData.select === '2' ? this.queryData.inputVal : '',
        title: this.queryData.select === '1' ? this.queryData.inputVal : '',
        courseTitle: this.queryData.lessonVal,
        catalogId: !this.queryData.catalogId ? null : this.queryData.catalogId[this.queryData.catalogId.length - 1],
        statusList:
          this.queryData.selectStatus === ''
            ? ['已发布', '未发布']
            : [this.queryData.selectStatus]
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 新建试卷
    addPaper () {
      this.paperDialog = true
    },

    // 查询试卷
    search () {
      this.pagination.currentPage = 1
      this.getExamList()
    },

    // 重置试卷
    reset () {
      this.queryData = {
        inputVal: '',
        select: '1',
        selectStatus: '',
        lessonVal: ''
      }
    },

    // 编辑试卷
    editPaper (row) {
      this.editPaperData = row
      this.paperDialog = true
    },

    // 发布试卷
    publicPaper (row) {
      this.$confirm('是否发布当前试卷，发布成功后试卷不可修改', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await editExam({
            id: row.id,
            status: '已发布'
          })
          window.$msg('发布成功')
          this.getExamList()
        })
        .catch(() => {
          return false
        })
    },

    // 停用试卷、
    pausePaper (row) {
      this.$confirm('是否停用当前试卷', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await editExam({
            id: row.id,
            status: '已停用'
          })
          window.$msg('停用成功')
          this.getExamList()
        })
        .catch(() => {
          return false
        })
    },

    // 关闭试卷
    closePaper (row) {
      this.$confirm('是否发布当前试卷，关闭成功后试卷学生不可浏览', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await editExam({
            id: row.id,
            status: '未发布'
          })
          window.$msg('关闭成功')
          this.getExamList()
        })
        .catch(() => {
          return false
        })
    },

    // 删除试卷
    delPaper (row) {
      this.$confirm('确定删除当前试卷吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delExam({
            ids: [row.id]
          })
          window.$msg('删除成功')
          this.getExamList()
        })
        .catch(() => {
          return false
        })
    },

    // 复制试卷
    copyPaper (row) {
      // console.log('复制试卷', row)
      this.editPaperData = row
      this.isCopy = true
      this.paperDialog = true
    },

    // 预览试卷
    previewPaper (row) {
      let routeUrl = this.$router.resolve({
        path: '/research/exam-details',
        query: { examID: row.id }
      })
      window.open(routeUrl.href, '_blank')
    },

    // 添加试卷
    addData (data) {
      let nary = []
      data.questionList.forEach(element => {
        nary.push(element.questionId)
      })
      let newNary = Array.from(new Set(nary))
      if (nary.length !== newNary.length) {
        window.$msg('试卷中不允许存在相同试题', 2)
      } else {
        addExam({
          title: data.title,
          limitTime: data.limitTime,
          autoResult: data.autoResult,
          isRepeatable: data.isRepeatable,
          isShowScore: data.isShowScore,
          questionList: data.questionList,
          status: '已发布',
          catalogId: typeof (data.catalogId) === 'string' ? data.catalogId : data.catalogId[data.catalogId.length - 1]
          // ...data
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('添加成功')
            this.paperDialog = false
            this.editPaperData = null
            this.isCopy = false
            this.getExamList()
          }
        })
      }
    },

    // 编辑试卷
    editData (data) {
      let nary = []
      data.questionList.forEach(element => {
        nary.push(element.questionId)
      })
      let newNary = Array.from(new Set(nary))
      if (nary.length !== newNary.length) {
        window.$msg('试卷中不允许存在相同试题', 2)
      } else {
        editExam({
          id: data.id,
          title: data.title,
          limitTime: data.limitTime,
          autoResult: data.autoResult,
          isRepeatable: data.isRepeatable,
          isShowScore: data.isShowScore,
          questionList: data.questionList,
          catalogId: typeof (data.catalogId) === 'string' ? data.catalogId : data.catalogId[data.catalogId.length - 1],
          // ...data
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('编辑成功')
            this.paperDialog = false
            this.editPaperData = null
            this.isCopy = false
            this.getExamList()
          }
        })
      }
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getExamList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getExamList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
